<template>
    <div>
        <tabs :tabs="tabs" class="border-bottom mb-2" />
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 150px);">
            <router-view />
        </div>
    </div>
</template>

<script>
export default {
    middleware:['auth'],
    data(){
        return {
            tabsReales: {
                tendero: [
                    { titulo: 'Dashboard', ruta: 'tendero.creditos.dashboard' },
                    { titulo: 'Clientes', ruta: 'tendero.creditos.clientes'},
                    { titulo: 'Cuotas', ruta: 'tendero.creditos.cuotas' }
                ],
                'tendero-vip': [
                    { titulo: 'Dashboard', ruta: 'tendero-vip.creditos.dashboard' },
                    { titulo: 'Clientes', ruta: 'tendero-vip.creditos.clientes'},
                    { titulo: 'Cuotas', ruta: 'tendero-vip.creditos.cuotas' }
                ]
            }
        }
    },
    computed: {
        tabs(){
            return this.tabsReales[this.$usuario.rol_nombre]
        }
    },
    mounted(){
        this.listarDatosGraficas()
    },
    methods:{
        listarDatosGraficas(){
            this.$store.commit('almacen/credito/set_grafica_dos_meses', [])
            this.$store.dispatch('almacen/credito/action_listar_creditos_grafica_dos_meses', 2) // 2 indica que es desde tendero
        }
    }
}
</script>
<style lang="scss" scoped>
.box{
    border-radius: 12px 12px 0px 0px !important;
    height:calc(100vh - 65px);
}
</style>
